import React from "react"
import { navigate } from "@reach/router"

import arrowRight from "../../img/icon-arrow_right_black.svg"
import "./styles.scss"

class BackButton extends React.Component {
    onClick = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    render() {
        return (
            <button {...this.props} className="btn-back" onClick={this.onClick}>
                <span className="icon flipped">
                    <img src={arrowRight} alt="Arrow Right Rotated" />
                </span>
                <span className="text"> Ga terug </span>
            </button>
        )
    }
}
export default BackButton
