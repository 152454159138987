import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ArtRollRelated from "../components/ArtRollRelated"
import { HTMLContent } from "../components/Content"
import { Helmet } from "react-helmet"

import "./styles/art-item.scss"

import SeeAllRow from "../components/SeeAllRow"
import BackButton from "../components/BackButton"
import { validURL } from "../utils/helpers.js"

export const ArtistItemTemplate = ({ description, title, dateDeath, dateBirth }) => {
    return (
        <div className="page--wrapper artist-page">
            <section className="art-item--page">
                <div className="sub-navigation">
                    <BackButton />
                </div>
                <h1 className="page--heading">{title}</h1>

                <div className="item--info">
                    <div className="info--person">
                        <p className="person--dates">
                            <span className="date--birth">{dateBirth ? dateBirth : "Geboorte datum is onbekend"}</span>
                            <span className="date--dash"> — </span>
                            <span className="date--death">
                                {dateDeath ? dateDeath : "Datum van overlijden is onbekend"}
                            </span>
                        </p>
                        <div className="person--description">
                            {validURL(description) ? (
                                <a href={description} target="_blank" rel="noopener noreferrer">
                                    {description.toString()}
                                </a>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: description || "Onbekend" }}></div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <section className="section--related">
                <h2>Gemaakte kunstwerken</h2>
                <div className="container--related">
                    <ArtRollRelated name={title} />
                </div>
                <SeeAllRow to="/search" />
            </section>
        </div>
    )
}

const ArtistItemPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <ArtistItemTemplate
                title={post.frontmatter.title}
                content={post.html}
                contentComponent={HTMLContent}
                description={post.html}
                dateBirth={post.frontmatter.dateBirth}
                dateDeath={post.frontmatter.dateDeath}
                helmet={
                    <Helmet titleTemplate="%s | Geportretteerde">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta name="description" content={`${post.html}`} />
                    </Helmet>
                }
            />
        </Layout>
    )
}

export default ArtistItemPage

export const pageQuery = graphql`
    query ArtistPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                description
                dateBirth
                dateDeath
            }
        }
    }
`
